import React, { ChangeEvent, FormEvent, useState } from 'react';
import { TextField, Button, Box, Grid, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export const UploadPage = () => {
    const [clientName, setClientName] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string>('');
    const [transcript, setTranscript] = useState<any | null>(null)
    const [confirmation, setConfirmation] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [confirming, setConfirming] = useState(false)

    const handleClientNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setClientName(e.target.value);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const file = e.target.files !== null ? e.target.files[0] : null
        const fileExt = file?.name?.split('.')?.pop()?.toLowerCase();

        if (file && fileExt !== 'pdf') {
            setError('Only PDF files are allowed')
        } else {
            setSelectedFile(file);
            setConfirmation(false)
        }
    }

    const handleSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('clientName', clientName);
        if (selectedFile !== null) {
            formData.append('file', selectedFile);
        }

        try {
            setSubmitting(true)

            const resp = await fetch('/api/upload', {
                method: 'POST',
                body: formData
            });

            if (resp.status === 200) {
                const data = await resp.json()
                setClientName('')
                setSelectedFile(null)
                setTranscript(data)
            } else {
                const error = await resp.text()
                setError(error)
            }

        } catch (e) {
            setError('Something went wrong')
        } finally {
            setSubmitting(false)
        }
    }

    const onConfirm = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        setConfirming(true)

        try {
            const resp = await fetch(`/api/confirm/${transcript?.id}`, {
                method: 'POST'
            })

            if (resp.status === 204) {
                setTranscript(null)
                setError('')
                setConfirmation(true)
            } else {
                const error = await resp.text()
                setError(error)
            }
        } catch (e) {
            setError('Something went wrong')
        } finally {
            setConfirming(false)
        }
    }

    if (transcript) {
        return (
            <div style={{
                margin: 20,
                display: 'grid',
                gridRowGap: 20,
                width: 'max-content',
                gridAutoRows: 'max-content'
            }}>
                <Alert severity="info"> The following information was extracted from the pdf. <br />
                    Click confirm to save the data.</Alert>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Request Date</TableCell>
                                <TableCell>{transcript.requestDate ?? ''}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Tracking Number</TableCell>
                                <TableCell>{transcript.trackingNumber ?? ''}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>{transcript.address ?? ''}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fiscal Year</TableCell>
                                <TableCell>{transcript.fiscalYear ?? ''}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fiscal Quarter</TableCell>
                                <TableCell>{transcript.fiscalQuater ?? ''}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(transcript?.transactions ?? [])
                                .filter((t: any) => ['841', '846'].includes(t.code))
                                .map((t: any) =>
                                    <TableRow>
                                        <TableCell>{t.code}</TableCell>
                                        <TableCell>{t.description}</TableCell>
                                        <TableCell align="right">{t.date}</TableCell>
                                        <TableCell align="right">{formatCurrency(t.amount)}</TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>
                    <LoadingButton
                        loading={confirming}
                        onClick={onConfirm}
                        variant="contained"
                    >
                        confirm
                    </LoadingButton>
                </div>
            </div >
        )
    } else {
        return (
            <div style={{
                margin: 20,
                display: 'grid',
                gridRowGap: 20,
                gridAutoRows: 'max-content',
                width: 350
            }}>
                {error ? <Alert severity="error">{error}</Alert> : null}
                {confirmation ? <Alert severity="success">Transcript successfully uploaded</Alert> : null}
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gridRowGap: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Client Name"
                                name="clientName"
                                type="text"
                                value={clientName}
                                onChange={handleClientNameChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" component="label">
                                Select File
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                />
                            </Button>
                            <span style={{ marginLeft: 20 }}>{selectedFile?.name}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={submitting}
                                disabled={!selectedFile || !clientName}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </div >
        )
    }
}



const formatCurrency = (num: number): string =>
    num?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'
