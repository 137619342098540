import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client'
import { HashRouter, Route, NavLink, Routes, } from 'react-router-dom';
import { HomePage } from './pages/top-level/HomePage';
import { LoadingButton } from '@mui/lab';
import { UploadPage } from './pages/top-level/UploadPage';

const container = document.getElementById('root')
const root = container && createRoot(container)

const activeStyle = {
    color: '#097fdb',
    fontWeight: 900
}

const style = {
    fontFamily: 'sans-serif',
    textDecoration: 'none',
    color: '#4f4f4f',
    fontSize: 15
}

console.log('app')

const App = () => {

    const [refreshing, setRefreshing] = useState(false)
    const [latestScrape, setLatestScrape] = useState(null as null | Date)

    useEffect(() => {
        fetch(`/api/latest-scrape`, {
            method: 'GET'
        }).then(async (resp) => {
            if (resp.status === 200) {
                const data = await resp.json()
                setLatestScrape(new Date(data.date))
            }
        })
    }, [])

    const onRefresh = async (e) => {
        e.preventDefault();

        if (confirm('Click OK to start a new data scrape. Check back in 30 minutes for the results.')) {

            try {
                setRefreshing(true)

                const resp = await fetch(`/api/refresh`, {
                    method: 'POST'
                })

                if (resp.status === 204) {
                    alert('Data scrape initiated')
                } else if (resp.status === 503) {
                    alert('Currently, a data scrape is in progress. Please wait until the current process completes before initiating a new scrape.')
                } else {
                    alert('Something went wrong')
                }

            } catch (e) {
                alert('Something went wrong')
            } finally {
                setRefreshing(false)
            }

        }
    }

    return <div
        style={{
            display: 'grid',
            height: '100vh',
            gridTemplateRows: 'max-content 1fr 10px'
        }}
    >
        <div
            style={{
                padding: 10,
                display: 'grid',
                gridAutoFlow: 'column',
                alignItems: 'baseline',
                gridAutoColumns: '1fr',
                gridTemplateColumns: 'repeat(3,max-content)',
                gridColumnGap: 20,
                borderBottom: '1px solid dimgrey',
            }}
        >
            <span
                style={{
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold'
                }}
            > ERC Tracker </span>
            <NavLink
                style={({ isActive }) => ({
                    ...style,
                    ...(isActive ? activeStyle : {})
                })}
                to="/">Report</NavLink>
            <NavLink
                style={({ isActive }) => ({
                    ...style,
                    ...(isActive ? activeStyle : {})
                })}
                to="/upload">Upload Transcript</NavLink>

            <div
                style={{
                    display: 'grid',
                    gridColumnGap: 10,
                    fontSize: 14,
                    alignItems: 'baseline',
                    gridAutoFlow: 'column',
                    textAlign: 'right',
                    fontFamily: 'sans-serif',
                    gridAutoColumns: 'max-content',
                    justifyContent: 'end'
                }}
            >
                {latestScrape ?
                    <span>Latest Update: {latestScrape.toLocaleDateString()} {latestScrape.toLocaleTimeString()}</span>
                    : null
                }
                <LoadingButton
                    loading={refreshing}
                    onClick={onRefresh}
                    variant="contained"
                >
                    refresh
                </LoadingButton>
            </div>

        </div>
        <Routes>
            <Route path={'/'} element={<HomePage />} />
            <Route path={'/upload'} element={<UploadPage />} />
        </Routes >
    </div >
}


root?.render(
    <HashRouter>
        <App />
    </HashRouter >
)


