import * as React from 'react'
import { useState, useEffect, Fragment } from 'react'
import ExcelJS from 'exceljs'

import styled from 'styled-components'

const CenteredElement = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`

interface HomePageProps {
    spreadsheetId?: string
    username?: string
}

export const HomePage = (props: HomePageProps) => {

    const { username, spreadsheetId } = props
    const [state, setState] = useState('pending' as 'pending' | 'resolved' | 'rejected')
    const [transcripts, setTranscritps] = useState([] as any[])

    useEffect(() => {
        setState('pending')

        Promise.all([
            fetch(`/api/fundings?spreadsheetId=${spreadsheetId ?? ''}`),
            fetch(`/api/transcripts?username=${username ?? ''}`)
        ]).then(async ([fundingsResp, transcriptsResp]) => {
            if (fundingsResp.status !== 200 ||
                transcriptsResp.status !== 200) {
                setState('rejected')
            } else {
                const fundingData: any[] = await fundingsResp.json()
                const trData: any = await transcriptsResp.json()

                const fundingMap = new Map()
                fundingData.forEach(f => {
                    fundingMap.set(
                        sanitizeString(f.entityName),
                        f
                    )
                })

                const trs = (trData?.transcripts ?? []).map((t: any) => {
                    const f = fundingMap.get(t._id)

                    return {
                        ...t,
                        ...f
                    }
                }).filter((tr: any) =>
                    tr.clientID
                )

                setTranscritps(trs)
                setState('resolved')
            }

        }).catch((e) => {
            console.error(e)
            setState('rejected')
        })
    }, [username, spreadsheetId])

    switch (state) {
        case 'pending':
            return (<CenteredElement>loading...</CenteredElement>)
        case 'rejected':
            return <CenteredElement>
                something went wrong :(
            </CenteredElement>
        case 'resolved':

            const qsToRender = [
                '22020',
                '32020',
                '42020',
                '12021',
                '22021',
                '32021',
                '42021'
            ]

            const quaterTitles = qsToRender.reduce((acc: any[], q) => {
                const quater = q.substring(0, 1)
                const year = q.substring(3, 5)

                acc.push({
                    className: 'number',
                    text: `Q${quater} '${year} Refund`,
                    numFmt: '$#,##0.00'
                })

                acc.push({
                    className: 'number',
                    text: `Q${quater} '${year} Date`,
                })

                return acc
            }, [])

            const headers = [
                {
                    className: '',
                    text: 'Entity Name'
                },
                {
                    className: '',
                    text: 'Entity DBA'
                },
                {
                    className: '',
                    text: 'ID'
                },
                {
                    className: 'number',
                    text: 'Funded Date'
                },
                {
                    className: 'number',
                    text: 'Refund Total',
                    numFmt: '$#,##0.00'
                },
                {
                    className: 'number',
                    text: 'Funded Amount'
                },
                {
                    className: 'number',
                    text: 'Latest Transcript'
                },
                {
                    className: '',
                    text: 'Address'
                },
                {
                    className: '',
                    text: 'Prev. Address'
                },
                ...quaterTitles
            ]

            const getRefund = (transcript: any) => {
                if (!transcript?.refunds) {
                    return null
                } else if (transcript.cancelledRefunds.length) {
                    const rs = transcript.refunds?.filter((r: any) => {
                        return transcript.cancelledRefunds
                            .every((cr: any) => cr.date !== r.date)
                    })
                    return rs?.[rs.length - 1]
                } else {
                    return transcript.refunds[transcript.refunds.length - 1]
                }
            }

            const isCancelled = (transcript: any) => {
                if (transcript.cancelledRefunds?.length < 1) {
                    return false
                } else if (
                    transcript.cancelledRefunds.length === transcript.refunds?.length
                ) {
                    return true
                } else {
                    return false
                }
            }

            const rows = transcripts.map((obj) => {
                const quarters = obj.quarters

                const refundTotal = quarters.reduce((sum: number, q: any) => {
                    return sum + (getRefund(q.transcripts?.[0])?.amount ?? 0)
                }, 0)

                const address = quarters[0]?.transcripts?.[0]?.address ?? ''

                const prevAddress = quarters[0]?.transcripts
                    ?.find((t: any) => t.address !== address)
                    ?.address ?? address

                const wrongAddress = !['2952 S', 'PO BOX', ''].includes(address)

                const lt = new Date(obj.lastTranscriptDate)

                const latestTranscriptString = `${lt.getMonth() + 1}/${lt.getDate()}/${lt.getFullYear()}`

                const quaterRows = quarters.reduce((acc: any[], q: any) => {
                    const tr = q.transcripts[0]
                    const cafFail = tr?.status === 'CAF Check Failed'
                    const refundAmount = getRefund(tr)?.amount

                    const previousTranscript = q.transcripts.filter((t: any) => {
                        return (
                            t.status !== 'CAF Check Failed' &&
                            t.requestDate !== tr.requestDate
                        )
                    })[0]

                    const previousNonCAFTranscript = q.transcripts.filter((t: any) => {
                        return (
                            t.status !== 'CAF Check Failed'
                        )
                    })[0]

                    const previousNonCafRefoundAmount = getRefund(previousNonCAFTranscript)?.amount

                    const newRefund = refundAmount && getRefund(previousTranscript)?.amount !== refundAmount
                    const refundString = formatCurrency(refundAmount)

                    const cancelled = isCancelled(tr)

                    const refund = (() => {
                        if (cafFail) {
                            const refundStr = formatCurrency(previousNonCafRefoundAmount)
                            return previousNonCafRefoundAmount ? `CAF (${refundStr})` : 'CAF'
                        } else if (cancelled) {
                            return 'Cancelled'
                        } else if (refundString) {
                            return `${refundString}`
                        } else {
                            return '-'
                        }
                    })()

                    const href: string | null = (() => {
                        if (cafFail) {
                            return previousNonCafRefoundAmount ? `api/transcript/${previousNonCAFTranscript.id}` : null
                        } else {
                            return tr ? `api/transcript/${tr.id}` : null
                        }
                    })()

                    const date: string = (() => {
                        if (cafFail) {
                            return previousNonCafRefoundAmount ? getRefund(previousNonCAFTranscript).date : 'N/A'
                        } else {
                            return tr && getRefund(tr) ? getRefund(tr).date : 'N/A'
                        }
                    })()

                    const refundClass = (() => {
                        if (cafFail) {
                            return 'caf'
                        } else if (cancelled) {
                            return 'alert'
                        } else if (newRefund) {
                            return 'new'
                        } else {
                            return ''
                        }
                    })()

                    acc.push({
                        className: `number ${refundClass}`,
                        value: refundAmount,
                        text: `${newRefund ? '(new)' : ''} ${refund}`
                    })

                    if (href) {
                        acc.push({
                            value: date,
                            text: date,
                            className: 'number',
                            href
                        })
                    } else {
                        acc.push({
                            value: date,
                            text: date,
                            className: 'number'
                        })
                    }

                    return acc

                }, [])

                const refundTotalString = (() => {
                    if (isNaN(refundTotal)) {
                        return '-'
                    } else {
                        return formatCurrency(refundTotal)
                    }
                })()

                return [
                    {
                        value: obj.entityName,
                        text: obj.entityName,
                        className: ''
                    },
                    {
                        value: obj.clientDBA,
                        text: obj.clientDBA ?? '',
                        className: ''
                    },
                    {
                        value: obj.clientID,
                        text: obj.clientID ?? '',
                        className: ''
                    },
                    {
                        value: obj.fundedDate,
                        text: obj.fundedDate ?? '',
                        className: 'number'
                    },
                    {
                        value: refundTotal,
                        text: refundTotalString ?? '',
                        className: 'number'
                    },
                    {
                        value: obj.fundedAmount,
                        text: formatCurrency(obj.fundedAmount),
                        className: 'number'
                    },
                    {
                        value: latestTranscriptString,
                        text: latestTranscriptString,
                        className: 'number'
                    },
                    {
                        value: address,
                        text: address,
                        className: wrongAddress ? 'alert' : ''
                    },
                    {
                        value: prevAddress,
                        text: prevAddress,
                        className: ''
                    },
                    ...quaterRows
                ]

            })

            const exportToExcel = () => {
                const data = [
                    [
                        ...headers.map(h => h.text)
                    ],
                    ...rows.map(r =>
                        r.map(c => c.value)
                    )
                ]

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet 1');

                worksheet.columns = [
                    { width: 35 },
                    { width: 35 },
                    { width: 7 },
                    ...headers.slice(3).map(h => ({ width: 15 }))
                ]

                const xlsxData = [headers, ...rows]

                xlsxData.forEach((row, rowIndex) => {
                    row.forEach((c, colIndex) => {
                        let cell = worksheet.getCell(rowIndex + 1, colIndex + 1);
                        cell.value = c.value ?? c.text;

                        // Apply right alignment to the columns at index 1 and 2
                        if (headers[colIndex].className === 'number') {
                            cell.alignment = { horizontal: 'right' };
                        }

                        if (rowIndex > 0 && headers[colIndex].numFmt) {
                            cell.numFmt = headers[colIndex].numFmt
                        }

                        if (c.className.includes('new')) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: '8af18a' }
                            };
                        } else if (c.className.includes('caf')) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'ffbe48' }
                            };
                            cell.value = c.text
                        } else if (c.className.includes('alert')) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'ee8b8b' }
                            };
                            cell.value = c.text
                        }
                    });
                });

                data[0].forEach((value, colIndex) => {
                    let cell = worksheet.getCell(1, colIndex + 1);
                    cell.font = { bold: true };
                });

                workbook.xlsx.writeBuffer().then((data) => {
                    var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    var downloadLink = window.document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = `erc-tracker-${new Date().toJSON()}.xlsx`
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                });
            }

            return <div role="region" aria-labelledby="caption" tabIndex={0}>
                <table id="fixed-table">
                    <thead>
                        <tr>
                            {headers.map((h, i) => {
                                if (i === 0) {
                                    return (
                                        <th key={h.text}>
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr max-content'
                                                }}
                                            >
                                                {h.text}
                                                <span style={{
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                }} onClick={exportToExcel}>xlsx</span>
                                            </div>
                                        </th>
                                    )
                                } else {
                                    return <th key={h.text} className={h.className}>{h.text}</th>
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((r) =>
                            <tr key={r[0].value}>
                                {r.map((c, i) => {
                                    if (i === 0) {
                                        return <th key={r[0].value + i} className={c.className}>{c.text}</th>
                                    } else {
                                        return <td key={r[0].value + i} className={c.className}>
                                            {c.href ?
                                                <a href={c.href}>{c.text}</a> :
                                                c.text
                                            }
                                        </td>
                                    }
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div >
    }

}

const formatCurrency = (num: number): string =>
    num?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'

function sanitizeString(input: string): string {
    // Remove all non-alphanumeric characters except spaces
    let sanitized = input.replace(/[^a-zA-Z0-9\s]|\t/g, '');

    // Replace multiple spaces with a single space
    sanitized = sanitized?.replace(/\s{2,}/g, ' ');

    return sanitized?.toLowerCase()?.trim();
}
